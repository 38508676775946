"use client";

import { redirect, RedirectType } from "next/navigation";
import { ofetch } from "ofetch";
import { newsletterPreferencesPaths } from "routes";
import useSWRMutation from "swr/mutation";
import { z } from "zod";

import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/controls/TextInput";
import Form from "@/components/forms/Form";
import { useZodForm } from "@/hooks/useZodForm";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";

interface NewsletterFormProps {
  locale: AllowedLanguages;
  location?: string;
  searchQuery?: string;
}

export const NewsletterForm = ({
  locale,
  location,
  searchQuery,
}: NewsletterFormProps) => {
  const { t } = useTranslation(locale);
  const schema = z.object({
    email: z.string().email(t("error.invalid_email")).toLowerCase(),
    first_name: z
      .string()
      .min(1, t("error.required"))
      .transform((val, ctx) => {
        return val.replace(/(<([^>]+)>)/gi, "");
      }),
    last_name: z
      .string()
      .min(1, t("error.required"))
      .transform((val, ctx) => {
        return val.replace(/(<([^>]+)>)/gi, "");
      }),
  });

  const form = useZodForm({
    schema,
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
    },
  });

  async function subscribe(
    url: string,
    {
      arg,
    }: {
      arg: z.infer<typeof schema>;
    },
  ) {
    return await ofetch<string>(url, {
      method: "POST",
      body: {
        ...arg,
        locale,
      },
    });
  }

  const { data, trigger, isMutating, error } = useSWRMutation(
    "/api/newsletter",
    subscribe,
  );

  const handleSubmit = async (data: z.infer<typeof schema>) => {
    await trigger(data);
  };

  if (data?.key) {
    const url = new URL(
      `${process.env.NEXT_PUBLIC_BASE_URL}/${locale}/myvivaldis/${newsletterPreferencesPaths[locale]}`,
    );
    url.searchParams.set("key", data.key);
    url.searchParams.set("location", location ?? "");
    url.searchParams.set("searchQuery", searchQuery ?? "");

    redirect(url.toString(), RedirectType.push);
  }

  return (
    <Form
      form={form}
      className="grid grid-cols-12 gap-x-6 gap-y-6 xl:gap-x-10"
      onSubmit={handleSubmit}
      noValidate={true}
    >
      <div className="col-span-12 md:col-span-6">
        <TextInput
          label={t("newsletter.first_name.label")}
          name="first_name"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <TextInput
          label={t("newsletter.last_name.label")}
          name="last_name"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 md:col-span-6 2xl:col-span-8">
        <TextInput
          label={t("newsletter.email.label")}
          name="email"
          type="email"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 flex md:col-span-6 2xl:col-span-4">
        <Button
          type="submit"
          className="btn-white btn-lg btn-outline mt-auto whitespace-nowrap border-2"
          hasLoader={true}
          isLoading={isMutating}
          disabled={isMutating}
        >
          {t("newsletter.submit")}
        </Button>
      </div>
      <div className="col-span-12">
        <div className="inline-flex flex-wrap items-center gap-1 font-normal text-white">
          {t("newsletter.privacyStatement.label")}
        </div>
      </div>
    </Form>
  );
};

export default NewsletterForm;
